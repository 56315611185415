<template>
    <div class="portfolio-piece">
        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>Full Plate<span> Ramen Edition</span></h1>
                    <h1>Full Plate<span> Ramen Edition</span></h1>
                </div>
            </div>
        </div>
        <h2>Editorial Design | Print | Original Artwork</h2>

        <div class="portfolio-cont" :style="{'background-image': `url(${require('../assets/images/portfolio/fp-backdrop.png')})`}">

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="full plate logo with ramen artwork" src="../assets/images/portfolio/fp-logo.webp">
                    </figure>
                </div>
                <div>
                    <p>For the ramen-based edition of San Antonio food magazine Full Plate, a special approach was required. It began with colors and typography that bring out the tones in the ramen soup. 
                        Additionally, original artwork would be created for the covers and diagram. This worked in tandem with the other elements of the design to establish a unique look for the piece.</p>
                </div>
            </div>
        
            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="full plate magazine unfolded" src="../assets/images/portfolio/fp-unfolded.webp">
                        <figcaption>This magazine was designed as a single large sheet that folds into the booklet</figcaption>
                    </figure>
                </div>
                <div>
                    <figure class="medium-img">
                        <img alt="full plated folded magazine" src="../assets/images/portfolio/fp-folded.webp">
                    </figure>
                </div>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="the first inner spread of the magazine" src="../assets/images/portfolio/fp-spread1.webp">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="a later spread from inside the magazine" src="../assets/images/portfolio/fp-spread2.webp">
                </figure>
            </div>

            <div class="p-content-row-2">
                <div>
                    <figure class="medium-img">
                        <img alt="the infographic on the back of the page" src="../assets/images/portfolio/fp-info-unfolded.webp">
                        <figcaption>When unfolded, a diagram of ramen ingredients can be viewed on the back and displayed as a poster</figcaption>
                    </figure>
                </div>
                <div>
                    <figure class="medium-img">
                        <img alt="ramen diagram from the infographic" src="../assets/images/portfolio/fp-info.webp">
                    </figure>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "PortfolioFullPlate",

}
</script>

<style lang="scss" scoped>

.portfolio-cont {
    background-repeat: no-repeat;
    background-size: 75%;

    @include respond-to('small') {
        background-size: 100%;
    }
}

</style>